<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="ADMINS"
          :breadcrumb="[
            {
              label: 'Admins',
            },
            { label: 'Admins-List' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card  custom-card"
           
            >
              <div class="card-body">

                <div class="row">
                  <div class="col text font">
                    <h4><b style="color: #00364f; font-weight: bolder">Admin </b></h4>
                  </div>
                  <div class="col text-end ">
                    <router-link :to="`/super-admin/create-admins?portal=${portal}`">
                      <button
                        title="Add New Admin"
                        type="button"
                        class="btn text-white btn-sm"
                        style="background-color: red"
                      >
                        <b> New Admin</b>
                      </button>
                    </router-link>
                    &nbsp;<button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>

                  </div>
                  <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">

                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>

                </div>


                <!-- start list area  -->
                <div class="row">
                

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style="margin-top: 7px"
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm" style="font-size: 14px">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>&nbsp;&nbsp;+&nbsp;&nbsp;</th>
                          <th>Code</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Services</th>
                          <th>Kyc</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(admininfo, index) in admins"
                            :key="index"
                          >
                            <td>
                              <img
                                style="
                                  width: 30px;
                                  height: 30px;
                                  margin-right: 5px;
                                  border-radius: 5px;
                                "
                                :src="adminlogofinder(admininfo, admininfo.photo)"
                                alt=""
                              />
                            </td>
                            <td>
                              <span v-if="admininfo" class="text-truncate">{{
                                admininfo.code
                              }}</span>
                            </td>
                            <td>
                              <span v-if="admininfo" class="text-truncate">
                                <strong>{{ admininfo.orgnization }}</strong></span
                              >
                              <br />
                              <span v-if="admininfo" class="text-truncate"
                                ><small
                                  >(<i>{{ admininfo.name }}</i
                                  >)</small
                                >
                              </span>
                            </td>
                            <td>
                              <span v-if="admininfo" class="text-truncate">
                                {{ admininfo.phone }}</span
                              >
                            </td>
                            <td>
                              <span v-if="admininfo" class="text-truncate">{{
                                admininfo.email
                              }}</span>
                            </td>

                            <td class="text-center text-truncate">
                              <button
                                title="Services"
                                type="button"
                                @click="getServices(admininfo)"
                                data-bs-toggle="modal"
                                data-bs-target="#AdminServices"
                                class="btn text-white btn-sm"
                                style="
                                  background-color: rgb(242 19 1);
                                  padding: 5px 7px 5px 7px;
                                "
                              >
                                <b>
                                  <font-awesome-icon icon="link" />
                                </b>
                              </button>
                            </td>
                            <td>
                              <!-- {{ admininfo.admin_kyc.kycStatus }} -->

                              <span v-if="admininfo.admin_kyc">
                                <p
                                  v-if="admininfo.admin_kyc.kycStatus == 'approve'"
                                  class="text-center"
                                  style="
                                    background-color: #014a15;
                                    padding: 3px 3px 3px 3px;
                                    color: white;
                                    width: 20px;
                                    height: 20px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 12px;
                                  "
                                >
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                </p>
                                <p
                                  v-if="admininfo.admin_kyc.kycStatus == 'cancel'"
                                  class="text-white btn btn-sm"
                                  style="
                                    padding: 2px 3px 3px 3px;
                                    color: black;
                                    background-color: red;
                                    width: 20px;
                                    height: 20px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 12px;
                                  "
                                >
                                  <i class="fa fa-remove" aria-hidden="true"></i>
                                </p>
                                <p
                                  v-if="admininfo.admin_kyc.kycStatus == 'false'"
                                  class="text-white btn btn-sm"
                                  style="
                                    padding: 3.5px 3px 3px 3px;
                                    color: white;
                                    background-color: #3700fc;
                                    width: 20px;
                                    height: 20px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 12px;
                                  "
                                >
                                <i class="fa fa-clock-o" aria-hidden="true"></i>
                                </p>
                              </span>
                              <span v-else>
                                <p
                                  class="text-center"
                                  style="
                                    padding: 3.5px 3px 3px 3px;
                                    color: white;
                                    background-color: #3700fc;
                                    width: 20px;
                                    height: 20px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 12px;
                                  "
                                >
                                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                                </p>
                              </span>
                            </td>
                            <td>
                              <p
                                v-if="admininfo.loginStatus == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                            </td>
                            <td>
                              <div class="btn-group btn-group-sm" role="group">
                                <router-link
                                  title="View"
                                  :to="`admin-view-file/${admininfo.id}`"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link>
                                <router-link
                                  title="Edit"
                                  :to="`update-admins/${admininfo.id}?portal=${portal}`"
                                  class="btn btns btn-sm btn-success"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="edit" />
                                </router-link>
                                <router-link
                                  title="Kyc"
                                  to="#"
                                  @click="adminkycValueAsign(admininfo)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#kycModal"
                                  class="btn btns btn-sm text-dark"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: yellow;
                                  "
                                >
                                  <font-awesome-icon icon="file" />
                                </router-link>
                                <button
                                  title="Verify"
                                  v-if="admininfo.admin_kyc"
                                  @click="adminkycValueApprove(admininfo)"
                                  class="btn btns btn-sm"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #1426f5;
                                    color: white;
                                  "
                                  data-bs-toggle="modal"
                                  data-bs-target="#approoveKycModal"
                                >
                                  <b>
                                    <font-awesome-icon icon="tag" />
                                  </b>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
              <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadAdmins(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadAdmins(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadAdmins(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadAdmins(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadAdmins(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadAdmins(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadAdmins(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadAdmins(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadAdmins(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadAdmins( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadAdmins( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadAdmins(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadAdmins(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadAdmins(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadAdmins(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadAdmins(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
  <!-- Modal approoveKycModal-->

  <!-- modal for link Admin services services  -->

  <div
    class="modal fade"
    id="AdminServices"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div :class="(portal == 'false') ? 'modal-dialog modal-xl' : 'modal-dialog modal-lg'">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <b style="color: #00364f">Services & Commisions </b>
          </h5>
          <button
         
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="table-wrapper-scroll-y text tr-head rounded-circle">
                <th>Code</th>
                <th>Name</th>
                <th>Admin</th>
                <th v-if="portal == 'false'">Master Distributer</th>
                <th v-if="portal == 'false'">Distributer</th>
                <th v-if="portal == 'false'">Retailer</th>

                    <th class="text-end">

                      <span >
                        <input
                    
                          @click="adminServiceStatusBullk($event)"
                          type="checkbox"
                         v-model="countValueBool"
                          id="checkAllBox"
                        />
                      </span>
        
                    </th>

            
              </tr>

              <tbody>
                <tr
                  style="color: #00364f"
                  v-for="(service, index) in services"
                  :key="index"
                >
                  <td class="text-truncate">{{ service.code }}</td>
                  <td class="text-truncate">
                    {{ service.name }}
                  </td>

                  <td>
                    <span v-if="service">
                      <strong>{{ service.adminFee }}</strong></span
                    >
                 
                  </td>
                  <td v-if="portal == 'false'">
                    <div class="input-group">
                      <input
                        disabled
                        type="number"
                     
                        :value="service.masterDistributerFee"
                        class="form-control"
                        placeholder="Percentage"
                        style="height: 30px"
                      />
                      <span
                        style="height: 30px"
                        class="input-group-text"
                        id="basic-addon1"
                        ><strong>%</strong></span
                      >
                    </div>
                  </td>

                  <td v-if="portal == 'false'">
                    <div class="input-group">
                      <input
                        disabled
                        type="number"
                    
                        :value="service.distributerFee"
                        class="form-control"
                        placeholder="Percentage"
                        style="height: 30px"
                      />
                      <span
                        style="height: 30px"
                        class="input-group-text"
                        id="basic-addon1"
                        ><strong>%</strong></span
                      >
                    </div>
                  </td>
                  <td v-if="portal == 'false'">
                    <div class="input-group">
                      <input
                        disabled
                        type="number"
                    
                        :value="service.retailerFee"
                        class="form-control"
                        placeholder="Percentage"
                        style="height: 30px"
                      />
                      <span
                        style="height: 30px"
                        class="input-group-text"
                        id="basic-addon1"
                        ><strong>%</strong></span
                      >
                    </div>
                  </td>

                  <td class="text-end">
             
                    
                      <span v-if="service.admin_service_id">
                        <input
                    
                          @click="adminServiceStatus(service,  $event)"
                          type="checkbox"
                          :value="service.id"
                          checked
                        />
                      </span>
                      <span v-else>
                        <input
                    
                          @click="adminServiceStatus(service,  $event)"
                          type="checkbox"
                          :value="service.id"
                        />
                      </span>
    
                
                  </td>

                </tr>
              </tbody>
            </table>
            <!-- </form> -->
          </div>
        </div>
        <div class="modal-footer">
          <!-- @click="loadAdmins()" -->
          <button
 
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #f21300"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <!-- <button
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #00364f"
            @click="multipuleservicesAsignToAdmin()"
          >
            Confirm
          </button> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="kycModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel">
            <b>{{ kycModalHeading }}</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12" v-if="msgImgError">
              <div class="alert alert-danger p-1" role="alert">
                <small>{{ msgImgError }}</small>
              </div>
            </div>
          </div>
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="table-wrapper-scroll-y text tr-head rounded-circle">
                <th>+</th>
                <th>Ducument Name</th>
                <th class="text-end">Upload</th>
              </tr>

              <tbody>
                <!-- iojtoiejrteititiuretioeru -->
                <tr>
                  <td>
                    <img
                      style="width: 50px; height: 50px"
                      v-if="coiPreImg"
                      :src="coiPreImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="coi-img">COI</b>
                    <input
                      class="input-file"
                      @change="saveAdminKycDoccOnChange($event, 'coiPhoto')"
                      style="position: absolute; top: -150000px"
                      id="coi-img"
                      type="file"
                      accept="image/*"
                      required
                    />
                    <!-- <small style="color: red" v-if="formAdminKyc.errors"
                        ><span v-if="formAdminKyc.errors.error"
                          ><span v-if="formAdminKyc.errors.error.coiPhoto">{{
                            formAdminKyc.errors.error.coiPhoto[0]
                          }}</span></span
                        ></small
                      > -->
                  </td>
                  <td class="text-truncate text-end">
                    <label for="coi-img">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 50px; height: 50px"
                      v-if="tdsPreImg"
                      :src="tdsPreImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="tds-photo">TAN</b>
                    <input
                      class="input-file"
                      @change="saveAdminKycDoccOnChange($event, 'tdsPhoto')"
                      style="position: absolute; top: -150000px"
                      id="tds-photo"
                      type="file"
                      accept="image/*"
                      required
                    />
                    <!-- <small style="color: red" v-if="formAdminKyc.errors"
                        ><span v-if="formAdminKyc.errors.error"
                          ><span v-if="formAdminKyc.errors.error.tdsPhoto">{{
                            formAdminKyc.errors.error.tdsPhoto[0]
                          }}</span></span
                        ></small
                      > -->
                  </td>
                  <td class="text-truncate text-end">
                    <label for="tds-photo">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 50px; height: 50px"
                      v-if="gstinPreImg"
                      :src="gstinPreImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="gstin-photo">GSTIN</b>
                    <input
                      class="input-file"
                      @change="saveAdminKycDoccOnChange($event, 'gstinPhoto')"
                      style="position: absolute; top: -150000px"
                      id="gstin-photo"
                      type="file"
                      accept="image/*"
                      required
                    />
                    <!-- <small style="color: red" v-if="formAdminKyc.errors"
                        ><span v-if="formAdminKyc.errors.error"
                          ><span v-if="formAdminKyc.errors.error.gstinPhoto">{{
                            formAdminKyc.errors.error.gstinPhoto[0]
                          }}</span></span
                        ></small
                      > -->
                  </td>
                  <td class="text-truncate text-end">
                    <label for="gstin-photo">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 50px; height: 50px"
                      v-if="businessPanPreImg"
                      :src="businessPanPreImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="businessPan-photo">Business Pan </b>

                    <!-- @change="businessPanPhotoUpload" -->
                    <input
                      @change="saveAdminKycDoccOnChange($event, 'businessPanPhoto')"
                      class="input-file"
                      id="businessPan-photo"
                      style="position: absolute; top: -150000px"
                      type="file"
                      accept="image/*"
                      required
                    />
                    <!-- <small style="color: red" v-if="form.errors"
                        ><span v-if="form.errors.error"
                          ><span v-if="form.errors.error.businessPanPhoto">{{
                            form.errors.error.businessPanPhoto[0]
                          }}</span></span
                        ></small
                      > -->
                  </td>
                  <td class="text-truncate text-end">
                    <label for="businessPan-photo">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="container-fluid table-scroll">
            <form
              @submit.prevent="
                adminKycVal == 'false' ? adminkycCreate() : adminkycUpdate()
              "
            >
              <table class="table table-hover table-sm" style="font-size: 14px">
                <tbody>
                  <tr>
                    <td style="width: 25%">
                      <img
                        style="width: 50px; height: 50px"
                        v-if="adharFrontImg"
                        :src="adharFrontImg"
                      />
                    </td>

                    <td class="text-truncate text" style="width: 60%">
                      <b for="AdharFront">Admin Aadhaar Front  <small style="color:#f21000">*</small></b>
                      <input
                        @change="uploadAdharFront"
                        class="input-file"
                        style="position: absolute; top: -150000px"
                        id="AdharFront"
                        type="file"
                        accept="image/*"
                      />
                      <input
                        type="number"
                        v-model="form.aadharNo"
                        class="form-control"
                        placeholder="Adhar Number"
                        style="height: 30px"
                        required
                        @change="lenthheker($event, 'adminKycAddar')"
                      />
                      <span style="color: #f21000" id="adminKycAddar"></span>
                      <small style="color: red" v-if="form.errors"
                        ><span v-if="form.errors.error"
                          ><span v-if="form.errors.error.aadharNo">{{
                            form.errors.error.aadharNo[0]
                          }}</span></span
                        ></small
                      >
                    </td>
                    <td class="text-truncate text-end" style="width: 15%">
                      <label for="AdharFront">
                        <img
                          style="width: 25px; height: 25px"
                          src="/assets/image/upload.png"
                        />
                      </label>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <img
                        style="width: 50px; height: 50px"
                        v-if="adharBackImg"
                        :src="adharBackImg"
                      />
                    </td>

                    <td class="text-truncate text">
                      <b for="AdharBack">Admin Aadhaar Back </b>
                      <input
                        @change="uploadAdharBack"
                        class="input-file"
                        style="position: absolute; top: -150000px"
                        id="AdharBack"
                        type="file"
                        accept="image/*"
                      />
                    </td>
                    <td class="text-truncate text-end">
                      <label for="AdharBack">
                        <img
                          style="width: 25px; height: 25px"
                          src="/assets/image/upload.png"
                        />
                      </label>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <img
                        style="width: 50px; height: 50px"
                        v-if="panImg"
                        :src="panImg"
                      />
                    </td>

                    <td>
                      <b for="panphoto">Admin Pan <small style="color:#f21000">*</small></b>

                      <input
                        @change="uploadPanPhoto"
                        class="input-file"
                        style="position: absolute; top: -150000px"
                        id="panphoto"
                        type="file"
                        accept="image/*"
                      />
                      <input
                        v-if="panVarifyingStatus == 'true'"
                        disabled
                        type="tel"
                        maxlength="10"
                        v-model="form.panNo"
                        class="form-control"
                        placeholder="Pan Number"
                        style="height: 30px"
                        required
                        @change="businessPanVeryfied()"
                        @keydown="nameKeydown($event)"
                      />
                      <input
                        v-else
                        type="tel"
                        maxlength="10"
                        v-model="form.panNo"
                        class="form-control"
                        placeholder="Pan Number"
                        style="height: 30px"
                        required
                        @change="businessPanVeryfied()"
                        @keydown="nameKeydown($event)"
                      />
                      <span style="color: #f21000" id="adminPanVeryfied"></span>
                      <small style="color: red" v-if="form.errors"
                        ><span v-if="form.errors.error"
                          ><span v-if="form.errors.error.pan">{{
                            form.errors.error.pan[0]
                          }}</span></span
                        ></small
                      >
                    </td>
                    <td class="text-truncate text-end">
                      <label for="panphoto">
                        <img
                          style="width: 25px; height: 25px"
                          src="/assets/image/upload.png"
                        />
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="modal-footer text-end">
                <button
          
                  type="button"
                  class="btn btn-sm text-white"
                  style="background-color: #f21300"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                v-if="panVarifyingStatus == 'true'"
                  type="submit"
                  class="btn text-white btn-sm"
                  style="background-color: #00364f"
                >
                  Confirm
                </button>
                <button
                v-else
                  type="button"
                  class="btn text-white btn-sm"
                  style="background-color: #00364f"
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal kyc approve  -->
  <div
    class="modal fade"
    id="approoveKycModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel">
            <strong>Approve Kyc</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- <div class="container-fluid table-scroll"> -->
          <table class="table table-hover table-sm" style="font-size: 14px">
            <tr class="table-wrapper-scroll-y text tr-head rounded-circle">
              <th>&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Ducument Name</th>
              <th class="text-end">Download</th>
            </tr>

            <tbody>
              <tr>
                <td>
                  <img
                    style="width: 40px; height: 40px"
                    v-if="coiPreImg"
                    :src="coiPreImg"
                  />
                </td>

                <td class="text">
                  <b for="coi-img">COI &nbsp;&nbsp;</b><br>
                  <span v-if="approveMyAdmin">{{ approveMyAdmin.cin }}</span>
                </td>
                <td class="text-end">
                  <button
                    @click="
                      extensionCoi.toLowerCase() == 'pdf'
                        ? openWindow(coiPreImgPdf)
                        : openWindow(coiPreImg)
                    "
                    style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    class="btn btns text-white btn-sm"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>

              <tr>
                <td>
                  <img
                    style="width: 40px; height: 40px"
                    v-if="tdsPreImg"
                    :src="tdsPreImg"
                  />
                </td>

                <td class="text">
                  <b for="tds-photo">TAN &nbsp;&nbsp;</b><br>
                  <span v-if="approveMyAdmin">{{ approveMyAdmin.tds }}</span>
                </td>
                <td class="text-end">
                  <button
                    @click="
                      extensionTds.toLowerCase() == 'pdf'
                        ? openWindow(tdsPreImgPdf)
                        : openWindow(tdsPreImg)
                    "
                    style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    class="btn btns text-white btn-sm"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>

              <tr>
                <td>
                  <img
                    style="width: 40px; height: 40px"
                    v-if="gstinPreImg"
                    :src="gstinPreImg"
                  />
                </td>

                <td class="text">
                  <b for="gstin-photo">GSTIN &nbsp;&nbsp;</b><br>
                  <span v-if="approveMyAdmin">{{ approveMyAdmin.gstin }}</span>
                </td>
                <td class="text-end">
                  <button
                    @click="
                      extensionGstIn.toLowerCase() == 'pdf'
                        ? openWindow(GstInPreImgPdf)
                        : openWindow(gstinPreImg)
                    "
                    style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    class="btn btns text-white btn-sm"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>

              <tr>
                <td>
                  <img
                    style="width: 40px; height: 40px"
                    v-if="businessPanPreImg"
                    :src="businessPanPreImg"
                  />
                </td>

                <td class="text-truncate text">
                  <b for="businessPan-photo">Business Pan &nbsp;&nbsp;</b><br>
                  <span v-if="approveMyAdmin">{{ approveMyAdmin.businessPan }}</span>
                </td>
                <td class="text-truncate text-end">
                  <button
                    @click="
                      extensionBusinessPan.toLowerCase() == 'pdf'
                        ? openWindow(businessPanPreImgPdf)
                        : openWindow(businessPanPreImg)
                    "
                    style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    class="btn btns text-white btn-sm"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
              
              <tr>
                <td>
                  <img
                    style="width: 40px; height: 40px"
                    v-if="adharFrontImg"
                    :src="adharFrontImg"
                  />
                </td>

                <td class="text-truncate text">
                  <b for="AdharFront">Admin Aadhaar Front&nbsp;&nbsp;</b><br>
                  <span>{{ this.aadharNoForKyc }}</span>
                  <select
                    @change="aadharKycApprovedSave()"
                    class="form-select"
                    v-model="aadharapproveKyc.status"
                  >
                    <option value="approve" selected>Approve</option>
                    <!-- <option value="reject">Reject</option> -->
                    <option value="cancel">Cancel</option>
                  </select>
                </td>
                <td class="text-truncate text-end">
                  <!-- <label for="AdharFront"> -->
                  <button
                    @click="
                      extensionkycAdharFront.toLowerCase() == 'pdf'
                        ? openWindow(adharFrontImgPdf)
                        : openWindow(adharFrontImg)
                    "
                    style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    class="btn btns text-white btn-sm"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>

              <tr>
                <td>
                  <img
                    style="width: 40px; height: 40px"
                    v-if="adharBackImg"
                    :src="adharBackImg"
                  />
                </td>

                <td class="text-truncate text">
                  <b for="AdharBack">Admin Aadhaar Back</b>
                </td>
                <td class="text-truncate text-end">
                  <button
                    @click="
                      extensionkycAdharBack.toLowerCase() == 'pdf'
                        ? openWindow(adharBackImgPdf)
                        : openWindow(adharBackImg)
                    "
                    style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    class="btn btns text-white btn-sm"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>

              <tr>
                <td>
                  <img style="width: 40px; height: 40px" v-if="panImg" :src="panImg" />
                </td>

                <td class="text-truncate text">
                  <b for="panphoto">Admin Pan No &nbsp;&nbsp;</b>
                  <br>
                  <span> {{ this.panNoForKyc }}</span>
                  <select
                    v-model="panapproveKyc.status"
                    @change="panKycApprovedSave()"
                    class="form-select"
                  >
                    <option value="approve" selected>Approve</option>
                    <option value="cancel">Cancel</option>
                  </select>
                </td>
                <td class="text-truncate text-end">
                  <button
                    @click="
                      extensionkycPanPhoto.toLowerCase() == 'pdf'
                        ? openWindow(panImgPdf)
                        : openWindow(panImg)
                    "
                    style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    class="btn btns text-white btn-sm"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>

              <tr>
                <td>
                  <!-- <img style="width: 40px; height: 40px" v-if="panImg" :src="panImg" /> -->
                </td>

                <td class="text-truncate text">
                  <b for="panphoto">Admin Kyc</b>
                  <select v-model="approveKyc.status" class="form-select">
                    <option value="approve" selected>Approve</option>
                    <option value="cancel">Cancel</option>
                  </select>
                  <br />
                  <textarea
                    v-model="approveKyc.remark"
                    rows="2"
                    class="form-control"
                    placeholder="Remark"
                  ></textarea>
                </td>
                <td class="text-truncate text-end">
                  <!-- <button
                    type="button"
                    @click="adminKycApprovedSave"
                    class="btn btns text-white btn-sm"
                    style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                  >
                    <font-awesome-icon icon="floppy-disk" />
                  </button> -->
                </td>
              </tr>
            </tbody>
          </table>
          <!-- </div> -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm text-white"
              style="background-color: #f21300"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
            @click="adminKycApprovedSave"
                type="button"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            <!-- </div> -->
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
  </div>






     <!-- Right Sidebar starts -->
     <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">



            <ul class="list-group list-group-flush">
  <li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="code" class="form-check-input" type="radio" name="flexRadioDefault" id="code">
  <label class="form-check-label" for="code">
    Code
  </label>
</div>
  </li>
  <li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="orgnization" class="form-check-input" type="radio" name="flexRadioDefault" id="orgnization">
  <label class="form-check-label" for="orgnization">
    
    Orgnization
  </label>
</div>
</li>
  <!--   <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="dueDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Due Date
  </label>
</div>
</li> -->
<!-- <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="completionDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Completion Date
  </label>
</div>
  </li> -->
  <li class="list-group-item"><div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="name" class="form-check-input" type="radio" name="flexRadioDefault" id="name">
  <label class="form-check-label" for="name">
    Name
  </label>
</div></li>

<li class="list-group-item"><div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="email" class="form-check-input"  type="radio" name="flexRadioDefault" id="email">
  <label class="form-check-label" for="email">
    email
  </label>
</div></li>

<li class="list-group-item"><div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="phone" class="form-check-input"  type="radio" name="flexRadioDefault" id="phone">
  <label class="form-check-label" for="phone">
    phone
  </label>
</div></li>
<li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="all" class="form-check-input" type="radio" name="flexRadioDefault" id="all">
  <label class="form-check-label" for="all">
    All
  </label>
</div>
  </li>
</ul>


<div class="pt-1 pb-1">
  <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
 <!-- <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
 <input :type="filterType == 'phone' ? 'number' : 'text'" v-if="filterType != 'all'" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 <!-- <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
 







</div>

<button  @click="loadAdmins()" type="button" class="btn btn-success btn-sm">Success</button>



           
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
import Form from "vform";

var _ = require("lodash");
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "SuperAdminAdmins",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      filterValue:'',
      filterType:'',

      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page:10,
      approveMyAdmin: {},
      multipuleServiceFormforAdmin: {
        admin_id: null,
        services: [],
      },
      businessPanPreImgPdf: "",
      cinPreImgPdf: "",
      GstInPreImgPdf: "",
      coiPreImgPdf: "",
      tdsPreImgPdf: "",
      extensionCoi: "",
      extensionGstIn: "",
      extensionLogo: "",
      extensionTds: "",
      extensionBusinessPan: "",
      extensionBankStatement: "",
      extensionCin: "",
      bankStatementPreImg: this.$store.state.placeholderImg,
      businessPanPreImg: this.$store.state.placeholderImg,
      formAdminKyc: {
        field: "",
        documentFile: "",
      },
      userIds: [],
      allSelected: false,
      selected: [],

      portal: "",
      msgImgError: "",
      loading: false,
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      admins: [],
      services: [],
      countValueBool:'false',
      adminServices: [],
      admin_id: null,
      adminKycs: [],
      kycModalHeading: "",
      // admin kyc form vars
      placeholderImg: this.$store.state.placeholderImg,
      adminKyc_id: null,
      aadharNoForKyc: "",
      accountNoForKyc: "",
      panNoForKyc: "",
      adharFrontImg: this.$store.state.placeholderImg,
      adharFrontImgPdf: "",
      adharBackImg: this.$store.state.placeholderImg,
      adharBackImgPdf: "",
      panImg: this.$store.state.placeholderImg,
      panImgPdf: "",
      passBookImg: this.$store.state.placeholderImg,

      gstinPreImg: this.$store.state.placeholderImg,
      tdsPreImg: this.$store.state.placeholderImg,
      cinPreImg: this.$store.state.placeholderImg,
      coiPreImg: this.$store.state.placeholderImg,

      adminKycVal: "false",
      form: new Form({
        panNo: null,
        panPhoto: "",
        aadharNo: null,
        aadharFrontPhoto: "",
        aadharBackPhoto: "",
        remark: "",
        errors: {},
      }),
      // admin service charges vars
      admin_services_charge_form: {
        admin_id: null,
        serviceCharge: "",
        status: "",
        service_id: null,
        serviceChargeGstRequired: "true",
        masterDistributerFee: null,
        distributerFee: null,
        retailerFee: null,
        remark: "",
      },
      admServices: [],

      approveKyc: new Form({
        admin_id: null,
        type: "kyc",
        status: "cancel",
        remark: "",
      }),

      aadharapproveKyc: new Form({
        admin_id: null,
        type: "aadhar",
        status: "cancel",
        remark: "Aadhar Kyc",
      }),
      panapproveKyc: new Form({
        admin_id: null,
        type: "pan",
        status: "cancel",
        remark: "",
      }),
      // accountapproveKyc: new Form({
      //     admin_id: null,
      //     type: 'account',
      //     status: '',
      //     remark: '',
      // }),
      adminInformation: {},
      submitStatus: [],
      adminKycAddarstatus: "false",
      panVarifyingStatus: "false",
      adminServiceDelete: "",
    };

  },
  methods: {

    // adminServiceStatusBullk(services, event){

    // },
    saveAllservices(form,action){
      console.log(form)
var apiUrl = ''
      if(action == 'save'){
        apiUrl = 'superadmin/bulkadminservice'
      }else{
        apiUrl = 'superadmin/bulkdeleteadminservice'
      }

      this.loading = true;
        this.$axios
          .post(apiUrl, form, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data;
          
            if(action == 'save'){
              toast.success("Services are Asigned Succesfully", {
              autoClose: 1000,
            });
            }
            else{
              toast.success("Services are Deleted Succesfully", {
              autoClose: 1000,
            });
            }

           
            $("#AdminServices").modal("hide");
            this.loadAllServices()
          }).finally(() => (this.loading = false))
          .catch((error) => {

            this.$swal.fire({
                  position: "top-center",
                  icon: "error",
                  title: "<small>" + error.response.data.error.services + "</small>",
                  showConfirmButton: true,
                  confirmButtonColor: "#00364f",
                });



              });
    },

    adminServiceStatusBullk(event) {
   
      this.multipuleServiceFormforAdmin.admin_id = this.admin_id;
      const checkbox = document.getElementById('checkAllBox');
      if (event.target.checked == true) {
        this.services.forEach((value, index) => {
          index;
          var services = {
            service_id: `${value.id}`,
            serviceCharge: `${value.superAdminFee}`,
            serviceChargeGstRequired: `${value.superAdminFeeGstRequired}`,
          };
          this.multipuleServiceFormforAdmin.services.push(services);
        });
       
      if (checkbox) {
        checkbox.checked = false;
      }
      this.saveAllservices(this.multipuleServiceFormforAdmin , 'save')
      } else {
        // this.services.forEach((value, index) => {
        // this.multipuleServiceFormforAdmin.services.splice(
        //     this.multipuleServiceFormforAdmin.services.indexOf(this.services[index].id),
        //     1
        //   );
        // });
        this.services.forEach((value, index) => {
          index;
          var services = {
            service_id: `${value.id}`,
            serviceCharge: `${value.adminFee}`,
            serviceChargeGstRequired: "true",
          };
          this.multipuleServiceFormforAdmin.services.push(services);
        });
       
        
        this.saveAllservices(this.multipuleServiceFormforAdmin , 'delete')
        // this.$swal.fire({
        //           position: "top-center",
        //           icon: "error",
        //           title: "<small>Unchek APi Not Availible Yet Now Please Contact Backend Developer</small>",
        //           showConfirmButton: true,
        //           confirmButtonColor: "#00364f",
        //         });

        if (checkbox) {
        checkbox.checked = true;
      }



      }

    
    },
    adminServiceStatus(service, event){
      if(event.target.checked == true){
          console.log('true')

       var   services = {
        admin_id: this.admin_id,
            service_id: service.id,
            serviceCharge: service.adminFee,
            distributerFee: service.distributerFee,
            masterDistributerFee: service.masterDistributerFee,
            retailerFee: service.retailerFee,
            serviceChargeGstRequired: 'true',
            status: 'true',
          };
          

          this.loading = true;
        this.$axios
          .post(`superadmin/adminservice`,services, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data;

            this.loadAllServices();
     

          })
          .finally(() => (this.loading = false))
          .catch((error) => {
                this.$swal.fire({
                  position: "top-center",
                  icon: "error",
                  title: "<small>" + error.response.data.error + "</small>",
                  showConfirmButton: true,
                  confirmButtonColor: "#00364f",
                });
              });

          
      }else{
        console.log('false')


          this.loading = true;
        this.$axios
          .delete(`superadmin/adminservice/${service.admin_service_id}`, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data;

            this.loadAllServices()
     

          })
          .finally(() => (this.loading = false))
          .catch((error) => {
                this.$swal.fire({
                  position: "top-center",
                  icon: "error",
                  title: "<small>" + error.response.data.error + "</small>",
                  showConfirmButton: true,
                  confirmButtonColor: "#00364f",
                });
              });
      }

    },
    loadAllServices() {
      this.loading = true;
      this.$axios
        .get("superadmin/service?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.services = res.data.data;

          this.adminServicesLoad()
        })
        .finally(() => (this.loading = false))
    },
    adminServicesLoad(){
      this.loading = true;
      this.$axios
        .get(`superadmin/adminservice?all=all&admin_id=${this.admin_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {

          this.adminServices = res.data.data
         
       if(this.adminServices.length == this.services.length){
this.countValueBool = 'true'
       }else{
        this.countValueBool = 'false'
       }
          var serModule = this.services;
       
       _.forEach(this.adminServices, function (value) {
         if (
           _.find(serModule, function (o) {
             return o.id == value.service_id;
           })
         ) {
           var index = _.findIndex(serModule, function (o) {
             return o.id == value.service_id;
           });
           serModule[index].adminServicesStatus = value.status;
           serModule[index].admin_service_id = value.id;
           serModule[index].masterDistributerFee = value.masterDistributerFee;

            serModule[index].distributerFee = value.distributerFee;
            serModule[index].retailerFee = value.retailerFee;
            serModule[index].serviceChargeGstRequired = value.serviceChargeGstRequired;

          
         }
       });
       this.services = serModule;




           
        })
        .finally(() => (this.loading = false))



    },
    getServices(admin){
      this.loadAllServices()
      this.adminInformation = admin
      this.admin_id = admin.id
   
     
    },

    valueNullFilter(){
  this.filterValue = "";
},
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadAdmins();
    },
    businessPanVeryfied() {
      var data = this.form.panNo;
      if (data.length == 10) {
        // this.submitStatus = "false";
        $("#adminPanVeryfied").html("<small></small>");
        this.msgPanError = "";
        this.loading = true;
        this.$axios
          .post(
            "superadmin/verifypan",
            { panNo: this.form.panNo },
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            if (res.data.data.message) {
              // this.msgPanError = res.data.data.message;
              $("#adminPanVeryfied").html("<small>" + res.data.data.message + "</small>");
            }
            if (res.data.data.data) {
              $("#adminPanVeryfied").html(
                "<small>" + res.data.data.data.status + " Pan </small>"
              );
            }
            if (res.data.data.data.full_name) {
              this.submitStatus.splice(this.submitStatus.indexOf("adminPanVeryfied"), 1);
              $("#adminPanVeryfied").html("<small></small>");
              this.panVarifyingStatus = "true";
        
              toast.success(" Pan Verified Succesfully", {
          autoClose: 1000,
        });

            } else {
              this.msgPanError = res.data.data.message;
              if (this.submitStatus.length > 0) {
                for (var i = 0; i < this.submitStatus.length; i++) {
                  if (this.submitStatus[i] != "adminPanVeryfied") {
                    this.submitStatus.push("adminPanVeryfied");
                  }
                }
              } else {
                this.submitStatus.push("adminPanVeryfied");
              }
            }
          })  .finally(() => (this.loading = false))
          

          .catch((error) => {

this.$swal.fire({
      position: "top-center",
      icon: "error",
      title: "<small>" + error.response.data.error + "</small>",
      showConfirmButton: true,
      confirmButtonColor: "#00364f",
    });



  });




      } else {
        if (this.submitStatus.length > 0) {
          for (var i = 0; i < this.submitStatus.length; i++) {
            if (this.submitStatus[i] != "adminPanVeryfied") {
              this.submitStatus.push("adminPanVeryfied");
            }
          }
        } else {
          this.submitStatus.push("adminPanVeryfied");
        }
        $("#adminPanVeryfied").html(
          "<small> Admin Pan required minimum 10 Digits</small>"
        );
      }

      // this.panNo = ''
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        $("#adminPanVeryfied").html("<small>special characters are not allowed</small>");
        e.preventDefault();
      } else {
        $("#adminPanVeryfied").html("<small></small>");
      }
    },
    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "adminKycAddar") {
        if (data.length != 12) {
          this.adminKycAddarstatus = "true";

          $("#adminKycAddar").html("<small>Aadhar required  12 Digits</small>");
        }
        if (data.length == 12) {
          this.adminKycAddarstatus = "false";
          $("#adminKycAddar").html("<small></small>");
        }
      }
    },
    openWindow(url) {
      window.open(`${url}`);
    },
    saveAdminKycDoccOnChange(event, docc) {
      this.formAdminKyc.documentFile = event.target.files["0"];
      this.formAdminKyc.field = docc;
      // this.uploadDocc_form.alt = docc.document;
      // this.uploadDocc_form.caption = docc.document;

      if (docc == "coiPhoto") {
        this.msgImgError = "";
        if (event.target.files["0"].size > 2 * (1024 * 1024)) {
          event.preventDefault();
          this.msgImgError = "Coi File Size Too Big 2MB";
          return false;
        } else {
          var fileName;
          fileName = document.querySelector("#coi-img").value;
          this.extensionCoi = fileName.split(".").pop();

          if (
            this.extensionCoi == "png" ||
            this.extensionCoi == "jpeg" ||
            this.extensionCoi == "jpg" ||
            this.extensionCoi == "pdf" ||

            this.extensionCoi == "PNG" ||
            this.extensionCoi == "JPEG" ||
            this.extensionCoi == "JPG" ||
            this.extensionCoi == "PDF"
          ) {
            this.coiPreImg = URL.createObjectURL(event.target.files["0"]);
          } else {
            this.msgImgError = "File type must JPG ,JPEG, PNG , PDF ";
          }

          if (this.extensionCoi == "pdf" || this.extensionCoi == "PDF") {
            this.coiPreImg = "/assets/image/pdf.png";
          }
        }
      }

      if (docc == "tdsPhoto") {
        this.msgImgError = "";
        if (event.target.files["0"].size > 2 * (1024 * 1024)) {
          event.preventDefault();
          this.msgImgError = "TDS File Size Too Big 2MB";
          return false;
        } else {
          fileName;
          fileName = document.querySelector("#tds-photo").value;
          this.extensionTds = fileName.split(".").pop();

          if (
            this.extensionTds == "png" ||
            this.extensionTds == "jpeg" ||
            this.extensionTds == "jpg" ||
            this.extensionTds == "pdf" ||

            this.extensionTds == "PNG" ||
            this.extensionTds == "JPEG" ||
            this.extensionTds == "JPG" ||
            this.extensionTds == "PDF"
          ) {
            this.tdsPreImg = URL.createObjectURL(event.target.files["0"]);
          } else {
            this.msgImgError = "File type must JPG ,JPEG, PNG , PDF ";
          }

          if (this.extensionTds == "pdf" || this.extensionTds == "PDF") {
            this.tdsPreImg = "/assets/image/pdf.png";
          }
        }
      }

      if (docc == "cinPhoto") {
        this.cinPreImg = URL.createObjectURL(event.target.files["0"]);
        this.msgImgError = "";
        if (event.target.files["0"].size > 2 * (1024 * 1024)) {
          event.preventDefault();
          this.msgImgError = "CIN File Size Too Big 2MB";
          return false;
        } else {
          fileName;
          fileName = document.querySelector("#cin-photo").value;
          this.extensionCin = fileName.split(".").pop();

          if (
            this.extensionCin == "png" ||
            this.extensionCin == "jpeg" ||
            this.extensionCin == "jpg" ||
            this.extensionCin == "pdf" ||

            this.extensionCin == "PNG" ||
            this.extensionCin == "JPEG" ||
            this.extensionCin == "JPG" ||
            this.extensionCin == "PDF"
          ) {
            this.cinPreImg = URL.createObjectURL(event.target.files["0"]);
          } else {
            this.msgImgError = "File type must JPG ,JPEG, PNG , PDF ";
          }

          if (this.extensionCin == "pdf" || this.extensionCin == "PDF") {
            this.cinPreImg = "/assets/image/pdf.png";
          }
        }
      }
      if (docc == "gstinPhoto") {
        // this.gstinPreImg = event.target.files["0"];
        // this.gstinPreImg = URL.createObjectURL(event.target.files["0"]);

        this.msgImgError = "";
        if (event.target.files["0"].size > 2 * (1024 * 1024)) {
          event.preventDefault();
          this.msgImgError = "GSTIN File Size Too Big 2MB";
          return false;
        } else {
          fileName;
          fileName = document.querySelector("#gstin-photo").value;
          this.extensionGstIn = fileName.split(".").pop();

          if (
            this.extensionGstIn == "png" ||
            this.extensionGstIn == "jpeg" ||
            this.extensionGstIn == "jpg" ||
            this.extensionGstIn == "pdf" || 
            this.extensionGstIn == "PNG" ||
            this.extensionGstIn == "JPEG" ||
            this.extensionGstIn == "JPG" ||
            this.extensionGstIn == "PDF"
          ) {
            this.gstinPreImg = URL.createObjectURL(event.target.files["0"]);
          } else {
            this.msgImgError = "File type must JPG ,JPEG, PNG , PDF ";
          }

          if (this.extensionGstIn == "pdf" || this.extensionGstIn == "PDF") {
            this.gstinPreImg = "/assets/image/pdf.png";
          }
        }
      }
      if (docc == "businessPanPhoto") {
        // this.businessPanPreImg = URL.createObjectURL(event.target.files["0"]);

        this.msgImgError = "";
        if (event.target.files["0"].size > 2 * (1024 * 1024)) {
          event.preventDefault();
          this.msgImgError = "Business Pan File Size Too Big 2MB";
          return false;
        } else {
          fileName;
          fileName = document.querySelector("#businessPan-photo").value;
          this.extensionBusinessPan = fileName.split(".").pop();

          if (
            this.extensionBusinessPan == "png" ||
            this.extensionBusinessPan == "jpeg" ||
            this.extensionBusinessPan == "jpg" ||
            this.extensionBusinessPan == "pdf" ||

            this.extensionBusinessPan == "PNG" ||
            this.extensionBusinessPan == "JPEG" ||
            this.extensionBusinessPan == "JPG" ||
            this.extensionBusinessPan == "PDF"
          ) {
            this.businessPanPreImg = URL.createObjectURL(event.target.files["0"]);
          } else {
            this.msgImgError = "File type must JPG ,JPEG, PNG , PDF ";
          }

          if (this.extensionBusinessPan == "pdf" || this.extensionBusinessPan == "PDF") {
            this.businessPanPreImg = "/assets/image/pdf.png";
          }
        }
      }

      const formData = new FormData();
      const keys = Object.keys(this.formAdminKyc);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.formAdminKyc[keys[n]] !== null) {
          formData.append(keys[n], this.formAdminKyc[keys[n]]);
        }
      }

      this.$axios
        .post(`superadmin/admindocument/${this.admin_id}`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res.data;
        });
      this.loadAdmins();
    },




    aadharKycApprovedSave() {
      var kyc;
      if (this.aadharapproveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.aadharapproveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.aadharapproveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("superadmin/approveadminkyc", this.aadharapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res.data;
          toast.success("Aadhar Kyc " + kyc + " Succesfully", {
            autoClose: 1000,
          });
          this.loadAdmins();
        })
        .finally(() => (this.loading = false));
    },
    panKycApprovedSave() {
      var kyc;
      if (this.panapproveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.panapproveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.panapproveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("superadmin/approveadminkyc", this.panapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res.data;
          toast.success("Pan Kyc " + kyc + " Succesfully", {
            autoClose: 1000,
          });
          this.loadAdmins();
        })
        .finally(() => (this.loading = false));
    },
    adminKycApprovedSave() {
      var kyc;
      if (this.approveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.approveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.approveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("superadmin/approveadminkyc", this.approveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res.data;
          toast.success("Admin Kyc " + kyc + " Succesfully", {
            autoClose: 1000,
          });
          this.form = {};
          this.loadAdmins();
        })
        .finally(() => (this.loading = false));
    },
    gstinPhotoUpload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "GstIn Image Size Too Big 2MB";
        return false;
      }
      var fileName;
      fileName = document.querySelector("#gstin-photo").value;
      this.extensionGstIn = fileName.split(".").pop();
      if (
        this.extensionGstIn == "pdf" ||
        this.extensionGstIn == "png" ||
        this.extensionGstIn == "jpg" ||
        this.extensionGstIn == "jpeg" ||

        this.extensionGstIn == "PNG" ||
            this.extensionGstIn == "JPEG" ||
            this.extensionGstIn == "JPG" ||
            this.extensionGstIn == "PDF"
      ) {
        this.gstinPreImg = URL.createObjectURL(e.target.files["0"]);
        this.formAdminKyc.gstinPhoto = e.target.files["0"];
      } else {
        this.msgImgError = "File Type Must JPG , PNG, PDF ,JPEG";
      }
      if (this.extensionGstIn == "pdf" || this.extensionGstIn == "PDF") {
        this.gstinPreImg = "/assets/image/pdf.png";
      }
    },
    coiUpload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "COI Image Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#coi-img").value;
        this.extensionCoi = fileName.split(".").pop();
        if (
          this.extensionCoi == "pdf" ||
          this.extensionCoi == "png" ||
          this.extensionCoi == "jpg" ||
          this.extensionCoi == "jpeg" ||

          this.extensionCoi == "PNG" ||
            this.extensionCoi == "JPEG" ||
            this.extensionCoi == "JPG" ||
            this.extensionCoi == "PDF"
        ) {
          this.coiPreImg = URL.createObjectURL(e.target.files["0"]);
          this.formAdminKyc.coiPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File Type Must JPG , PNG, PDF ,JPEG";
        }
        if (this.extensionCoi == "pdf" || this.extensionCoi == "PDF") {
          this.coiPreImg = "/assets/image/pdf.png";
        }
      }
    },
    tdsPhotoUpload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "TDS Image Size Too Big 2MB";
        return false;
      }

      var fileName;
      fileName = document.querySelector("#tds-photo").value;
      this.extensionTds = fileName.split(".").pop();
      if (
        this.extensionTds == "png" ||
            this.extensionTds == "jpeg" ||
            this.extensionTds == "jpg" ||
            this.extensionTds == "pdf" ||

            this.extensionTds == "PNG" ||
            this.extensionTds == "JPEG" ||
            this.extensionTds == "JPG" ||
            this.extensionTds == "PDF"
      ) {
        this.tdsPreImg = URL.createObjectURL(e.target.files["0"]);
        this.formAdminKyc.tdsPhoto = e.target.files["0"];
      } else {
        this.msgImgError = "File Type Must JPG , PNG, PDF ,JPEG";
      }
      if (this.extensionTds == "pdf" || this.extensionTds == "PDF") {
        this.tdsPreImg = "/assets/image/pdf.png";
      }
    },

    cinPhotoUpload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "CIN Image Size Too Big 2MB";
        return false;
      }

      var fileName;
      fileName = document.querySelector("#cin-photo").value;
      this.extensionCin = fileName.split(".").pop();
      if (
        this.extensionCin == "png" ||
            this.extensionCin == "jpeg" ||
            this.extensionCin == "jpg" ||
            this.extensionCin == "pdf" ||

            this.extensionCin == "PNG" ||
            this.extensionCin == "JPEG" ||
            this.extensionCin == "JPG" ||
            this.extensionCin == "PDF"
      ) {
        this.cinPreImg = URL.createObjectURL(e.target.files["0"]);
        this.formAdminKyc.cinPhoto = e.target.files["0"];
      } else {
        this.msgImgError = "File Type Must JPG , PNG, PDF ,JPEG";
      }
      if (this.extensionCin == "pdf" || this.extensionCin == "PDF") {
        this.cinPreImg = "/assets/image/pdf.png";
      }
    },

    businessPanPhotoUpload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = " Business Pan Image Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#businessPan-photo").value;
        this.extensionBusinessPan = fileName.split(".").pop();

        if (
          this.extensionBusinessPan == "png" ||
            this.extensionBusinessPan == "jpeg" ||
            this.extensionBusinessPan == "jpg" ||
            this.extensionBusinessPan == "pdf" ||

            this.extensionBusinessPan == "PNG" ||
            this.extensionBusinessPan == "JPEG" ||
            this.extensionBusinessPan == "JPG" ||
            this.extensionBusinessPan == "PDF"
        ) {
          this.businessPanPreImg = URL.createObjectURL(e.target.files["0"]);
          this.form.businessPanPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File Type Must JPG , PNG, PDF ,JPEG";
        }

        if (this.extensionBusinessPan == "pdf" || this.extensionBusinessPan == "PDF") {
          this.businessPanPreImg = "/assets/image/pdf.png";
        }
      }
    },
   
    adminkycValueApprove(value) {
      console.log(value);
      this.admin_id = value.id;
      this.approveKyc.admin_id = value.id;
      this.approveKyc.remark = value.admin_kyc.remark;
      this.approveKyc.status = value.admin_kyc.kycStatus;
      this.panapproveKyc.admin_id = value.id;
      this.panapproveKyc.status = value.admin_kyc.panStatus;
      this.aadharapproveKyc.admin_id = value.id;
      this.aadharapproveKyc.status = value.admin_kyc.aadharStatus;
      this.panNoForKyc = value.admin_kyc.panNo;
      this.aadharNoForKyc = value.admin_kyc.aadharNo;
      this.approveMyAdmin = value;
      if (value.businessPanPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.businessPanPhoto
        ) {
          this.businessPanPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.businessPanPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/" +
          value.businessPanPhoto
        ) {
          this.businessPanPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/" +
            value.businessPanPhoto;
        }
        this.businessPanPreImgPdf = this.businessPanPreImg;
        this.extensionBusinessPan = this.businessPanPreImg.split(".").pop();

        if (this.extensionBusinessPan == "pdf" || this.extensionBusinessPan == "PDF") {
          this.businessPanPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.businessPanPreImg = this.$store.state.placeholderImg;
      }

      if (value.gstinPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.gstinPhoto
        ) {
          this.gstinPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.gstinPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/" +
          value.gstinPhoto
        ) {
          this.gstinPreImg =
            this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.gstinPhoto;
        }

        this.GstInPreImgPdf = this.gstinPreImg;
        this.extensionGstIn = this.gstinPreImg.split(".").pop();

        if (this.extensionGstIn == "pdf" || this.extensionGstIn == "PDF") {
          this.gstinPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.gstinPreImg = this.$store.state.placeholderImg;
      }

      if (value.cinPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.cinPhoto
        ) {
          this.cinPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.cinPhoto;
        }
        if (this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.cinPhoto) {
          this.cinPreImg =
            this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.cinPhoto;
        }

        this.cinPreImgPdf = this.cinPreImg;
        this.extensionCin = this.cinPreImg.split(".").pop();

        if (this.extensionCin == "pdf" || this.extensionCin == "PDF") {
          this.cinPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.cinPreImg = this.$store.state.placeholderImg;
      }

      if (value.coiPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.coiPhoto
        ) {
          this.coiPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.coiPhoto;
        }
        if (this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.coiPhoto) {
          this.coiPreImg =
            this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.coiPhoto;
        }

        this.coiPreImgPdf = this.coiPreImg;
        this.extensionCoi = this.coiPreImg.split(".").pop();

        if (this.extensionCoi == "pdf" || this.extensionCoi == "PDF") {
          this.coiPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.coiPreImg = this.$store.state.placeholderImg;
      }

      if (value.tdsPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.tdsPhoto
        ) {
          this.tdsPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.tdsPhoto;
        }
        if (this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.tdsPhoto) {
          this.tdsPreImg =
            this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.tdsPhoto;
        }

        this.tdsPreImgPdf = this.tdsPreImg;
        this.extensionTds = this.tdsPreImg.split(".").pop();

        if (this.extensionTds == "pdf" || this.extensionTds == "PDF") {
          this.tdsPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.tdsPreImg = this.$store.state.placeholderImg;
      }

      if (value.admin_kyc.panPhoto != null) {
        // this.panImg = this.$store.state.imgUrl + '/admin/' + value.id + '/thumbs/' + value.admin_kyc.panPhoto
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          value.id +
          "/thumbs/" +
          value.admin_kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/admin/" +
            value.id +
            "/thumbs/" +
            value.admin_kyc.panPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          value.id +
          "/" +
          value.admin_kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/admin/" +
            value.id +
            "/" +
            value.admin_kyc.panPhoto;
        }

        this.panImgPdf = this.panImg;
        this.extensionkycPanPhoto = this.panImg.split(".").pop();

        if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
          this.panImg = "/assets/image/pdf.png";
        }
      } else {
        this.panImg = this.$store.state.placeholderImg;
      }

      if (value.admin_kyc.aadharFrontPhoto != null) {
        // this.adharFrontImg = this.$store.state.imgUrl + '/admin/' + value.id + '/thumbs/' + value.admin_kyc.aadharFrontPhoto
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          value.id +
          "/thumbs/" +
          value.admin_kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/admin/" +
            value.id +
            "/thumbs/" +
            value.admin_kyc.aadharFrontPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          value.id +
          "/" +
          value.admin_kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/admin/" +
            value.id +
            "/" +
            value.admin_kyc.aadharFrontPhoto;
        }

        this.adharFrontImgPdf = this.adharFrontImg;
        this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

        if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharFrontImg = this.$store.state.placeholderImg;
      }

      if (value.admin_kyc.aadharBackPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          value.id +
          "/thumbs/" +
          value.admin_kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/admin/" +
            value.id +
            "/thumbs/" +
            value.admin_kyc.aadharBackPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          value.id +
          "/" +
          value.admin_kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/admin/" +
            value.id +
            "/" +
            value.admin_kyc.aadharBackPhoto;
        }

        this.adharBackImgPdf = this.adharBackImg;
        this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

        if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharBackImg = this.$store.state.placeholderImg;
      }
    },
 
 


  
    uploadAdharFront(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();

        if (this.submitStatus.length > 0) {
          for (var i = 0; i < this.submitStatus.length; i++) {
            if (this.submitStatus[i] != "adharFrontImg") {
              this.submitStatus.push("adharFrontImg");
            }
          }
        } else {
          this.submitStatus.push("adharFrontImg");
        }

        this.msgImgError = "Aadhar Front File Size Too Big 2MB";

        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#AdharFront").value;
        this.extensionkycAdharFront = fileName.split(".").pop();

        if (
          this.extensionkycAdharFront == "png" ||
          this.extensionkycAdharFront == "jpeg" ||
          this.extensionkycAdharFront == "jpg" ||
          this.extensionkycAdharFront == "pdf" ||
          this.extensionkycAdharFront == "PNG" ||
          this.extensionkycAdharFront == "JPEG" ||
          this.extensionkycAdharFront == "JPG" ||
          this.extensionkycAdharFront == "PDF"
        ) {
          this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
          this.form.aadharFrontPhoto = e.target.files["0"];

          console.log(this.form.aadharFrontPhoto);
          console.log(this.adharFrontImg);

          this.submitStatus.splice(this.submitStatus.indexOf("adharFrontImg"), 1);
        } else {
          this.msgImgError = "Aadhar Front File type must JPG ,JPEG, PNG , PDF ";
          if (this.submitStatus.length > 0) {
            for (i = 0; i < this.submitStatus.length; i++) {
              if (this.submitStatus[i] != "adharFrontImg") {
                this.submitStatus.push("adharFrontImg");
              }
            }
          } else {
            this.submitStatus.push("adharFrontImg");
          }
        }
        if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      }
    },
    uploadAdharBack(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Aadhar Back  File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#AdharBack").value;
        this.extensionkycAdharBack = fileName.split(".").pop();

        if (
            this.extensionkycAdharBack == "png" ||
          this.extensionkycAdharBack == "jpeg" ||
          this.extensionkycAdharBack == "jpg" ||
          this.extensionkycAdharBack == "pdf" ||
          this.extensionkycAdharBack == "PNG" ||
          this.extensionkycAdharBack == "JPEG" ||
          this.extensionkycAdharBack == "JPG" ||
          this.extensionkycAdharBack == "PDF"
        ) {
          this.adharBackImg = URL.createObjectURL(e.target.files["0"]);
          this.form.aadharBackPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG ,JPEG, PNG , PDF ";
        }

        if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      }
    },

    uploadPanPhoto(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Pan File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#panphoto").value;
        this.extensionkycPanPhoto = fileName.split(".").pop();

        if (
          this.extensionkycPanPhoto == "png" ||
          this.extensionkycPanPhoto == "jpeg" ||
          this.extensionkycPanPhoto == "jpg" ||
          this.extensionkycPanPhoto == "pdf" ||
          this.extensionkycPanPhoto == "PNG" ||
          this.extensionkycPanPhoto == "JPEG" ||
          this.extensionkycPanPhoto == "JPG" ||
          this.extensionkycPanPhoto == "PDF"
        ) {
          this.panImg = URL.createObjectURL(e.target.files["0"]);
          this.form.panPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG ,JPEG, PNG , PDF ";
        }

        if (this.extensionkycPanPhoto == "pdf") {
          this.panImg = "/assets/image/pdf.png";
        }
      }
    },
    adminKycLoad() {
      this.$axios
        .get("superadmin/adminkyc", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.adminKycs = res.data.data.data;
        });
    },
    adminkycValueAsign(value) {
      this.msgImgError = "";
      $("#adminKycAddar").html("<small></small>");
      $("#adminPanVeryfied").html("<small></small>");
      this.adharFrontImg = this.$store.state.placeholderImg;
      this.adharBackImg = this.$store.state.placeholderImg;
      this.panImg = this.$store.state.placeholderImg;
      this.form = {};
      this.admin_id = value.id;
      if (value.businessPanPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/" +
          value.businessPanPhoto
        ) {
          this.businessPanPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/" +
            value.businessPanPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.businessPanPhoto
        ) {
          this.businessPanPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.businessPanPhoto;
        }

        this.extensionBusinessPan = this.businessPanPreImg.split(".").pop();

        if (this.extensionBusinessPan == "pdf" || this.extensionBusinessPan == "PDF") {
          this.businessPanPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.businessPanPreImg = this.$store.state.placeholderImg;
      }

      if (value.gstinPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/" +
          value.gstinPhoto
        ) {
          this.gstinPreImg =
            this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.gstinPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.gstinPhoto
        ) {
          this.gstinPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.gstinPhoto;
        }

        this.extensionGstIn = this.gstinPreImg.split(".").pop();

        if (this.extensionGstIn == "pdf" || this.extensionGstIn == "PDF") {
          this.gstinPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.gstinPreImg = this.$store.state.placeholderImg;
      }

      if (value.cinPhoto != null) {
        if (this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.cinPhoto) {
          this.cinPreImg =
            this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.cinPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.cinPhoto
        ) {
          this.cinPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.cinPhoto;
        }

        this.extensionCin = this.cinPreImg.split(".").pop();

        if (this.extensionCin == "pdf" || this.extensionCin == "PDF") {
          this.cinPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.coiPreImg = this.$store.state.placeholderImg;
      }

      if (value.coiPhoto != null) {
        if (this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.coiPhoto) {
          this.coiPreImg =
            this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.coiPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.coiPhoto
        ) {
          this.coiPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.coiPhoto;
        }

        this.extensionCoi = this.coiPreImg.split(".").pop();

        if (this.extensionCoi == "pdf" || this.extensionCoi == "PDF") {
          this.coiPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.cinPreImg = this.$store.state.placeholderImg;
      }

      if (value.tdsPhoto != null) {
        if (this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.tdsPhoto) {
          this.tdsPreImg =
            this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.tdsPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.tdsPhoto
        ) {
          this.tdsPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.tdsPhoto;
        }

        this.extensionCin = this.tdsPreImg.split(".").pop();

        if (this.extensionCin == "pdf" || this.extensionCin == "PDF") {
          this.tdsPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.tdsPreImg = this.$store.state.placeholderImg;
      }

      // this.formAdminKyc = value;

      if (value.admin_kyc == null) {
        this.panVarifyingStatus = "false";
        this.kycModalHeading = "Admin Kyc";
        this.adminKycVal = "false";
      } else {
        this.kycModalHeading = "Admin Kyc";
        this.panVarifyingStatus = "true";
        this.form = value.admin_kyc;

        if (this.form.panPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/" +
            value.admin_kyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/admin/" +
              this.admin_id +
              "/" +
              value.admin_kyc.panPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.admin_kyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/admin/" +
              this.admin_id +
              "/thumbs/" +
              value.admin_kyc.panPhoto;
          }

          this.extensionkycPanPhoto = this.panImg.split(".").pop();

          if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
            this.panImg = "/assets/image/pdf.png";
          }
        } else {
          this.panImg = this.$store.state.placeholderImg;
        }

        if (this.form.aadharFrontPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/" +
            value.admin_kyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/admin/" +
              this.admin_id +
              "/" +
              value.admin_kyc.aadharFrontPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.admin_kyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/admin/" +
              this.admin_id +
              "/thumbs/" +
              value.admin_kyc.aadharFrontPhoto;
          }

          this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

          if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
            this.adharFrontImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharFrontImg = this.$store.state.placeholderImg;
        }

        if (this.form.aadharBackPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/" +
            value.admin_kyc.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/admin/" +
              this.admin_id +
              "/" +
              value.admin_kyc.aadharBackPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.admin_kyc.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/admin/" +
              this.admin_id +
              "/thumbs/" +
              value.admin_kyc.aadharBackPhoto;
          }

          this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

          if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
            this.adharBackImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharBackImg = this.$store.state.placeholderImg;
        }
        this.adminKycVal = "true";
      }
    },

    adminkycCreate() {
      const formData = new FormData();
      const keys = Object.keys(this.form);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.form[keys[n]] !== null) {
          formData.append(keys[n], this.form[keys[n]]);
        }
      }
      formData.append("admin_id", this.admin_id);
      // this.loading = true;
      if (this.adminKycAddarstatus == "false" && this.msgImgError == "") {
        this.$axios
          .post("superadmin/adminkyc", formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data;
            console.log("--------------------");
            console.log(res.data);
            toast.success("Kyc Created Succesfully", {
              autoClose: 1000,
            });
            this.form = {};
            $("#kycModal").modal("hide");
            this.loadAdmins();
          })
          .catch((error) => {
            this.form.errors = error.response.data;
            console.log("----------321----------");
            console.log(error.response.data.error.panNo[0]);
            this.$swal.fire({
              confirmButtonColor: "#00364f",
              icon: "error",
              title: "Oops...",
              text: error.response.data.error.panNo[0],
            });
          });
      }

      // this.adminOtherDocuments()
      // .finally(() => (this.loading = false));
    },

    adminkycUpdate() {
      const formData = new FormData();
      const keys = Object.keys(this.form);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.form[keys[n]] !== null) {
          formData.append(keys[n], this.form[keys[n]]);
        }
      }
      formData.append("admin_id", this.admin_id);
      if (this.adminKycAddarstatus == "false" && this.msgImgError == "") {
        this.$axios
          .post(`superadmin/adminkyc/${this.form.id}?_method=PUT`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data;

            toast.success("Kyc Updated Succesfully", {
              autoClose: 1000,
            });
            this.form = {};
            $("#kycModal").modal("hide");
            this.loadAdmins();
          })
          .catch((error) => {
            this.form.errors = error.response.data;
          });
      }
    },

    adminlogofinder(admin, imgUser) {
      if (imgUser != null) {
        this.photo =
          this.$store.state.imgUrl + "/admin/" + admin.id + "/thumbs/" + admin.logo;
        return this.photo;
      } else {
        this.photo = this.$store.state.placeholderImg;
        return this.photo;
      }
    },
    loadAdmins(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
      

        if( this.filterType == 'all'){
            this.filterValue = ''
            this.filterType = ''
          }

        


          if(this.filterValue != null && this.filterType != 'all'){
                      if(this.filterType == 'name' || this.filterType == 'orgnization'){
                        pageUrl += `superadmin/admin?portal=${this.portal}&per_page=${this.per_page}&searchTerm=${this.filterValue}`;
          
                      }else{
                        pageUrl += `superadmin/admin?portal=${this.portal}&per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
          
                      }
           }else{
            pageUrl += `superadmin/admin?portal=${this.portal}&per_page=${this.per_page}`;
           }



      } else {
        pageUrl += `&per_page=${this.pagination.per_page}&portal=${this.portal}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}&portal=${this.portal}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}&portal=${this.portal}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.admins = res.data.data.data;
          this.pagination = res.data.data;
          this.filterValue = ""
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.admin
    console.log(this.$store.state.superAdminPageTitles.admin)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.portal = this.$route.query.portal;
      this.loadAdmins();
      
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}

.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}


.form-check-input:checked {
    background-color: #ffffff;
    border-color: #f21000;
    border-width: 5px;
}
.form-check-input:not(:disabled):checked {
    box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}

.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}

</style>
